var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"compoWrap"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-position":"top"}},[_c('el-form-item',{attrs:{"label":"家族史"}},[_c('el-checkbox-group',{model:{value:(_vm.form.familyHistoryDtoList),callback:function ($$v) {_vm.$set(_vm.form, "familyHistoryDtoList", $$v)},expression:"form.familyHistoryDtoList"}},_vm._l((_vm.form.familyHistoryDtoList || []),function(ref,index){
var diseaseHistory = ref.diseaseHistory;
var diseaseDictCode = ref.diseaseDictCode;
var checkMark = ref.checkMark;
return (diseaseHistory)?_c('el-checkbox',{key:index,attrs:{"label":diseaseHistory,"checked":checkMark,"name":"type"}}):_vm._e()}),1)],1),_c('el-form-item',{attrs:{"label":"既往史"}},[_c('el-checkbox-group',{model:{value:(_vm.form.pastHistoryDto),callback:function ($$v) {_vm.$set(_vm.form, "pastHistoryDto", $$v)},expression:"form.pastHistoryDto"}},_vm._l((_vm.form.pastHistoryDto || []),function(ref,index){
var previousDisease = ref.previousDisease;
var diseaseDictCode = ref.diseaseDictCode;
var checkMark = ref.checkMark;
return (previousDisease)?_c('el-checkbox',{key:index,attrs:{"label":previousDisease,"checked":checkMark,"name":"type"}}):_vm._e()}),1)],1),_c('el-form-item',{attrs:{"label":"手术史"}},[_c('el-checkbox-group',{model:{value:(_vm.form.surgicalDto),callback:function ($$v) {_vm.$set(_vm.form, "surgicalDto", $$v)},expression:"form.surgicalDto"}},_vm._l((_vm.form.surgicalDto || []),function(ref,index){
var surgicalSiteDictCode = ref.surgicalSiteDictCode;
var surgicalSite = ref.surgicalSite;
var checkMark = ref.checkMark;
return (surgicalSite)?_c('el-checkbox',{key:index,attrs:{"label":surgicalSite,"checked":checkMark,"name":"type"}}):_vm._e()}),1)],1),_c('el-form-item',{attrs:{"label":"过敏史"}},_vm._l((_vm.form.allergyHistoryDtoList || []),function(ref,index){
var allergyTypeDict = ref.allergyTypeDict;
var allergyTypeDictCode = ref.allergyTypeDictCode;
var allergyDicts = ref.allergyDicts;
return _c('el-form-item',{key:index,attrs:{"label":allergyTypeDict}},[_c('el-checkbox-group',{model:{value:(allergyDicts),callback:function ($$v) {allergyDicts=$$v},expression:"allergyDicts"}},_vm._l((allergyDicts || []),function(ref,ind){
var allergyName = ref.allergyName;
var allergyDictCode = ref.allergyDictCode;
var checkMark = ref.checkMark;
return (allergyName)?_c('el-checkbox',{key:ind,attrs:{"label":allergyName,"checked":checkMark,"name":"type"}}):_vm._e()}),1)],1)}),1),_c('el-form-item',{attrs:{"label":"用药史"}},[_c('el-checkbox-group',{model:{value:(_vm.form.medicationHistorys),callback:function ($$v) {_vm.$set(_vm.form, "medicationHistorys", $$v)},expression:"form.medicationHistorys"}},_vm._l((_vm.form.medicationHistorys || []),function(ref,index){
var drugDictCode = ref.drugDictCode;
var drug = ref.drug;
var checkMark = ref.checkMark;
return (drug)?_c('el-checkbox',{key:index,attrs:{"label":drug,"checked":checkMark,"name":"type"}}):_vm._e()}),1)],1),_c('el-form-item',{staticClass:"flex",attrs:{"label":"吸烟史"}},[(
          _vm.form.smokingHistoryDto.smokingDicts &&
          _vm.form.smokingHistoryDto.smokingDicts.length
        )?_c('el-checkbox-group',{model:{value:(_vm.form.smokingHistoryDto.smokingDicts),callback:function ($$v) {_vm.$set(_vm.form.smokingHistoryDto, "smokingDicts", $$v)},expression:"form.smokingHistoryDto.smokingDicts"}},_vm._l((_vm.form
            .smokingHistoryDto.smokingDicts || []),function(ref,index){
            var smokingDictCode = ref.smokingDictCode;
            var smokingDict = ref.smokingDict;
            var checkMark = ref.checkMark;
return (smokingDict)?_c('el-checkbox',{key:index,attrs:{"label":smokingDict,"checked":checkMark,"name":"type"}}):_vm._e()}),1):_vm._e(),_c('span',[_vm._v("吸烟年限（年）：")]),_vm._v(" "+_vm._s(_vm.form.smokingHistoryDto.smokingYears)+"     "),_c('span',[_vm._v("吸烟频率（支/天）：")]),_vm._v(" "+_vm._s(_vm.form.smokingHistoryDto.smokingFrequency)+" ")],1),_c('el-form-item',{attrs:{"label":"饮酒史"}},[(
          _vm.form.drinkingHistoryDto.drinking &&
          _vm.form.drinkingHistoryDto.drinking.length
        )?_c('el-checkbox-group',{model:{value:(_vm.form.drinkingHistoryDto.drinking),callback:function ($$v) {_vm.$set(_vm.form.drinkingHistoryDto, "drinking", $$v)},expression:"form.drinkingHistoryDto.drinking"}},_vm._l((_vm.form
            .drinkingHistoryDto.drinking || []),function(ref,index){
            var drinkingDictCode = ref.drinkingDictCode;
            var drinking = ref.drinking;
            var checkMark = ref.checkMark;
return (drinking)?_c('el-checkbox',{key:index,attrs:{"label":drinking,"checked":checkMark,"name":"type"}}):_vm._e()}),1):_vm._e(),_c('span',[_vm._v("饮酒年限（年）：")]),_vm._v(" "+_vm._s(_vm.form.drinkingHistoryDto.drinkingYears)+"     "),_c('span',[_vm._v("饮酒频率：")]),_vm._v(" "+_vm._s(_vm.form.drinkingHistoryDto.drinkingFrequency)+" ")],1),_c('el-form-item',{attrs:{"label":"口味偏好"}},[_c('el-checkbox-group',{model:{value:(_vm.form.tastesHistoryDto),callback:function ($$v) {_vm.$set(_vm.form, "tastesHistoryDto", $$v)},expression:"form.tastesHistoryDto"}},_vm._l((_vm.form.tastesHistoryDto || []),function(ref,index){
            var diseaseDictCode = ref.diseaseDictCode;
            var previousDiseases = ref.previousDiseases;
            var checkMark = ref.checkMark;
return (previousDiseases)?_c('el-checkbox',{key:index,attrs:{"label":previousDiseases,"checked":checkMark,"name":"type"}}):_vm._e()}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }