<template>
  <div class="mainWrap">
    <div class="topTitle">
      健康档案/档案查询<el-button
        @click="boBack"
        type="primary"
        class="returnButton"
        >返回</el-button
      >
    </div>
    <el-row class="tac" :gutter="5">
      <el-col :span="3">
        <el-menu
          default-active="0"
          class="el-menu-vertical-demo"
          @select="selectMenu"
        >
          <el-menu-item
            :index="`${index}`"
            v-for="({ name, id }, index) in menu"
            :key="id"
          >
            <span slot="title" class="memuTitle">{{ name }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="21">
        <component :is="curComponent" :userId="userId"></component>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BaseInfo from './components/baseInfo.vue';
import History from './components/history.vue';

import BodyData from './components/bodyData.vue';

const menu = [
  {
    name: '基础信息',
    component: 'BaseInfo',
    id: 1,
  },
  {
    name: '既往病史/生活习惯',
    component: 'History',
    id: 2,
  },

  {
    name: '体征数据',
    component: 'BodyData',
    id: 4,
  },
];
export default {
  components: {
    BaseInfo,
    History,

    BodyData,
  },
  computed: {
    curComponent() {
      return this.menu[this.activeContent].component;
    },
  },
  data() {
    return {
      userId: '',
      menu,
      activeContent: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    to.meta.keepAlive = false;
    next();
  },
  created() {
    this.userId = this.$route.query.userId;
    this.activeContent = 0;
  },
  methods: {
    selectMenu(index) {
      this.activeContent = index;
      console.log(this.activeContent);
    },
    boBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.mainWrap {
  background: #fff;
  .topTitle {
    font-size: 16px;
    height: 80px;
    line-height: 80px;
    border-bottom: 1px solid #eee;
    text-indent: 20px;
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
  }
  .returnButton {
    height: 40px;
    margin-top: 20px;
  }
  .memuTitle {
    font-size: 10px;
    display: block;

    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
