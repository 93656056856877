<template>
  <div class="compoWrap">
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item label="家族史">
        <el-checkbox-group v-model="form.familyHistoryDtoList">
          <el-checkbox
            :label="diseaseHistory"
            v-if="diseaseHistory"
            :checked="checkMark"
            name="type"
            v-for="(
              { diseaseHistory, diseaseDictCode, checkMark }, index
            ) in form.familyHistoryDtoList || []"
            :key="index"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="既往史">
        <el-checkbox-group v-model="form.pastHistoryDto">
          <el-checkbox
            :label="previousDisease"
            v-if="previousDisease"
            :checked="checkMark"
            name="type"
            v-for="(
              { previousDisease, diseaseDictCode, checkMark }, index
            ) in form.pastHistoryDto || []"
            :key="index"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="手术史">
        <el-checkbox-group v-model="form.surgicalDto">
          <el-checkbox
            v-if="surgicalSite"
            :label="surgicalSite"
            :checked="checkMark"
            name="type"
            v-for="(
              { surgicalSiteDictCode, surgicalSite, checkMark }, index
            ) in form.surgicalDto || []"
            :key="index"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="过敏史">
        <el-form-item
          :label="allergyTypeDict"
          v-for="(
            { allergyTypeDict, allergyTypeDictCode, allergyDicts }, index
          ) in form.allergyHistoryDtoList || []"
          :key="index"
        >
          <el-checkbox-group v-model="allergyDicts">
            <el-checkbox
              v-if="allergyName"
              :label="allergyName"
              :checked="checkMark"
              name="type"
              v-for="(
                { allergyName, allergyDictCode, checkMark }, ind
              ) in allergyDicts || []"
              :key="ind"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form-item>
      <el-form-item label="用药史">
        <el-checkbox-group v-model="form.medicationHistorys">
          <el-checkbox
            v-if="drug"
            :label="drug"
            :checked="checkMark"
            name="type"
            v-for="(
              { drugDictCode, drug, checkMark }, index
            ) in form.medicationHistorys || []"
            :key="index"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="吸烟史" class="flex">
        <el-checkbox-group
          v-model="form.smokingHistoryDto.smokingDicts"
          v-if="
            form.smokingHistoryDto.smokingDicts &&
            form.smokingHistoryDto.smokingDicts.length
          "
        >
          <el-checkbox
            v-if="smokingDict"
            :label="smokingDict"
            :checked="checkMark"
            name="type"
            v-for="({ smokingDictCode, smokingDict, checkMark }, index) in form
              .smokingHistoryDto.smokingDicts || []"
            :key="index"
          ></el-checkbox>
        </el-checkbox-group>
        <span>吸烟年限（年）：</span>
        {{ form.smokingHistoryDto.smokingYears }}&nbsp;&nbsp;&nbsp;&nbsp;
        <span>吸烟频率（支/天）：</span>
        {{ form.smokingHistoryDto.smokingFrequency }}
      </el-form-item>
      <el-form-item label="饮酒史">
        <el-checkbox-group
          v-model="form.drinkingHistoryDto.drinking"
          v-if="
            form.drinkingHistoryDto.drinking &&
            form.drinkingHistoryDto.drinking.length
          "
        >
          <el-checkbox
            v-if="drinking"
            :label="drinking"
            :checked="checkMark"
            name="type"
            v-for="({ drinkingDictCode, drinking, checkMark }, index) in form
              .drinkingHistoryDto.drinking || []"
            :key="index"
          ></el-checkbox>
        </el-checkbox-group>
        <span>饮酒年限（年）：</span>
        {{ form.drinkingHistoryDto.drinkingYears }}&nbsp;&nbsp;&nbsp;&nbsp;
        <span>饮酒频率：</span>
        {{ form.drinkingHistoryDto.drinkingFrequency }}
      </el-form-item>
      <el-form-item label="口味偏好">
        <el-checkbox-group v-model="form.tastesHistoryDto">
          <el-checkbox
            :label="previousDiseases"
            :checked="checkMark"
            name="type"
            v-for="(
              { diseaseDictCode, previousDiseases, checkMark }, index
            ) in form.tastesHistoryDto || []"
            :key="index"
            v-if="previousDiseases"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryAllHealthHistory } from '@/api/healthRecord';
function destructureWithDefault(obj, key, defaultValue) {
  const value = obj[key];
  return value !== undefined && value !== null ? value : defaultValue;
}

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        // 家族史
        familyHistoryDtoList: [
          // diseaseHistory	string	 疾病名
          // diseaseDictCode	string	疾病code
          // checkMark	boolean	选择标记 true 选择
        ],
        // 必须 既往史
        pastHistoryDto: [
          // diseaseDictCode	string 既往编码
          // previousDisease	string 既往疾病名称
          // checkMark	boolean
        ],
        //必须 手术史
        surgicalDto: [
          // surgicalSiteDictCode string 手术部位字典
          // surgicalSite string 手术部位
          // checkMark boolean
        ],
        //必须 过敏史
        allergyHistoryDtoList: [
          // allergyTypeDictCode	string 过敏源大类code
          // allergyTypeDict string 过敏源大类名
          // allergyDicts [
          //     allergyName	string 过敏原名称
          //     allergyDictCode	string 过敏原字典编码
          //     checkMark	boolean
          // ]
        ],
        //必须 吸烟史
        smokingHistoryDto: {
          smokingDicts: [],

          // smokingFrequency	integer	 吸烟频率（支/天）
          // smokingYears	integer	 吸烟年限（年）
          // smokingDicts [
          //     smokingDictCode	string	 吸烟状态字典
          // smokingDict	string	 吸烟状态名
          // checkMark	boolean
          // ]
        },
        //必须 饮酒史
        drinkingHistoryDto: {
          drinking: [],
          // drinking	object [   饮酒状态列表
          //     drinkingDictCode	string	 饮酒状态编码
          //     drinking	string	 饮酒状态
          //     markCheck	boolean
          // ]
          // drinkingFrequencyDictCode	string	 饮酒频率字典
          // drinkingFrequency	string	 饮酒频率
          // drinkingYears	integer	 饮酒年限（年）
        },
        //必须 口味偏好
        tastesHistoryDto: [
          // diseaseDictCode	string	 口味偏好字典
          // previousDiseases	string	 口味偏好名称
          // checkMark	boolean
        ],
        //必须 用药史
        medicationHistorys: [
          // drugDictCode	string	 药物编码
          // drug	string	 药物名称
          // checkMark	boolean	 是否选择
        ],
      },
    };
  },
  created() {
    const params = {
      userId: this.userId,
    };
    this.form = this.$options.data().form;
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    });
    queryAllHealthHistory(params).then((res) => {
      const { data = {} } = res;

      this.form = {
        medicationHistorys: destructureWithDefault(
          data,
          'medicationHistorys',
          []
        ),
        tastesHistoryDto: destructureWithDefault(data, 'tastesHistoryDto', []),
        drinkingHistoryDto: destructureWithDefault(
          data,
          'drinkingHistoryDto',
          {}
        ),
        smokingHistoryDto: destructureWithDefault(
          data,
          'smokingHistoryDto',
          {}
        ),
        allergyHistoryDtoList: destructureWithDefault(
          data,
          'allergyHistoryDtoList',
          []
        ),
        surgicalDto: destructureWithDefault(data, 'surgicalDto', []),
        pastHistoryDto: destructureWithDefault(data, 'pastHistoryDto', []),
        familyHistoryDtoList: destructureWithDefault(
          data,
          'familyHistoryDtoList',
          []
        ),
      };

      console.log(this.form, ' this.form');
      loading.close();
    });
  },
};
</script>

<style lang="scss" scoped>
.compoWrap {
  padding: 20px;
  width: 100%;
  height: 90vh;
  background-color: #fff;
  overflow-y: scroll;
}
</style>
